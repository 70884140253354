.header {
    z-index: 50;
    margin: 0 1vw;
    margin-bottom: 2vw;
    pointer-events: none;
    display: flex;
}

.header h1 {
    color: white;
    opacity: 0.9;

    font-family: 'Lexend Mega', sans-serif;
    margin: 0;
    font-weight: normal;
    -webkit-text-stroke-width: 3px;
    pointer-events: none;
    font-size: 2.5rem;

    flex: 1;
}

.socialMediasArea {
    display: flex;
    align-items: flex-end;
}

@media only screen and (max-width: 640px) {
    .header h1 {
        font-size: 2.3em;
    }
}