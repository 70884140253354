
.portfolioFilters {
    padding-top: 1vw;
    margin: 0 1vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portfolioFilters>div {
    display: flex;
    gap: 0.5em;
    align-items: center;

}
.portfolioFilters .label {
    color: rgba(255,255,255,1);
    margin-left: 0.2em;
}

.portfolioFilters .label img, .portfolioFilters button img {
    width: 0.9em;
    height: 0.9em;
    opacity: 0.7;
}

.tags {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tags li, .portfolioFilters button {
    display: inline-block;
    color: rgba(255,255,255,0.7);
    background-color: rgba(255,255,255,0);
    padding: 0.2em 0.3em;
    margin-right: 0.2em;
    border-radius: 0.6em;
    transition: background-color 0.1s;
    cursor: pointer;
}

.portfolioFilters button {
    border: none;
    display: flex;
    align-items: center;
    font-size: 1em;
    gap: 0.5em;
    margin: 0;
}

.tags li.active {
    background-color: rgba(255,255,255,0.1);
}
.tags li:hover, .portfolioFilters button:hover {
    background-color: rgba(255,255,255,0.1);

}

.portfolioGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    padding-top: 1rem;
    grid-auto-flow: dense;
}
    /*
.portfolioGrid .item:nth-child(2) {
    grid-column: auto / span 2;
}*/
/*
.portfolioGrid .item:nth-child(2), .portfolioGrid .item:nth-child(12) {
    grid-column: 2 / 4;
}

.portfolioGrid .item:nth-child(6) {
    grid-column: 2 / 4;
}
*/
.portfolioGrid .item:nth-child(2):before, .portfolioGrid .item:nth-child(12):before, .portfolioGrid .item:nth-child(6):before {
    padding-top: 50%;
}

.portfolioGrid .item {
    position: relative;
    top: 0;
    transition: top ease 0.3s;
    max-width: 100%;
    text-decoration: none;
    color: #fff;
}

.portfolioGrid .item.larger {
    grid-column: auto / span 2;
}


.portfolioGrid .item::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
}
.portfolioGrid .item.larger::before {
    padding-top: 50%;

}


.portfolioGrid .item::after { /* to clear float */
    content: "";
    display: table;
    clear: both;
}

.itemInner {
    z-index: 30;
    margin: 1vw; /* this is what we use for the gap */
    padding: 2.5rem;
    font-size: 1em;
    display: flex;
    font-family: 'Noto Sans', sans-serif;
    flex-direction: column;
    height: calc(100% - 5rem - 2vw);
}

.itemInner * {
    z-index: 50;
}

.itemContent {
    flex: 1;
}

.itemContent h2 {
    margin: 0;
    line-height: 1.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
    font-weight: 600;
    display: block;
    text-decoration: none;
    font-family: 'Noto Sans', sans-serif;
    opacity: 0.9;
}

.itemContent .itemDescription {
    line-height: 1.7em;
    opacity: 0.7;
    font-family: 'Noto Sans', sans-serif;
}

.portfolioGrid .item[href]:hover {
    top: -5px;
}

.itemBackground {
    position: absolute;
    top: 0; left: 0; width: calc(100% - 2vw); height: calc(100% - 2vw);
    margin: 1vw;
    border-radius: 6px;
    border-radius: 20px;
    height: calc(100% - 2vw);
    background-size: cover;
    background-position: center;
    /* maybe even background-attachment: fixed; ? */
    z-index: 0;
    filter: contrast(0.6) brightness(0.4) saturate(1.3) grayscale(0.3);
}


video.itemBackground {
    width: calc(100% - 2vw);
    object-fit: cover;
}


.mediaLogo img {
    opacity: 0.7;
    height: 1em;
    margin-top: 1rem;
    transition: opacity 0.1s ease-in-out;
}

.mediaLogo:hover img {
    opacity: 1;
}

.mediaLogo:not(:last-child) {
    margin-right: 1em;
}

a[href] .itemDescription {
    text-decoration: none;
}
a[href] .itemContent h2::after {
    content: ' ';
    background-image: url(../images/tab.svg);
    width: 0.6em;
    height: 0.6em;
    display: inline-block;
    background-size: 100% 100%;
    opacity: 0.9;
    filter: invert(1);
    margin-left: 0.3em;
}




/* media queries */

@media only screen and (max-width: 990px) {
    .portfolioGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 640px) {
    .portfolioFilters {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 2rem;
    }
    .portfolioGrid {
        grid-template-columns: 1fr;
        grid-auto-rows: fit-content(1em);
        width: calc(100% + 2vw);
        margin-left: -1vw;
    }
    .portfolioGrid .item {
        grid-column: 1 / 2;
        margin-bottom: 0.5rem;
    }
    .itemInner {
        margin: 0;
        padding: 2rem;
        font-size: 1em;
        display: inline-block;
        height: unset;
    }
    .background {
        margin: 3rem 0;
    }
    .portfolioGrid .item:before {
        display: none; /* remove aspect ratios */
    }
}
