ul.socialMedias {
    list-style-type: none;
    margin: 0;
    padding: 0;
    pointer-events: auto; 
    opacity: 0.9;
}

ul.socialMedias li a {
    display: inline-block;
}

ul.socialMedias:hover li {
    opacity: 0.2;
}

ul.socialMedias li:hover {
    opacity: 0.85 !important;
}

ul.socialMedias li {
    display: inline-block;
    transition: opacity 0.1s ease-out;
    margin-left: 1em;
}

ul.socialMedias img {
    width: 2em;
    height: 2em;
    opacity: 0.85;
}


@media only screen and (max-width: 640px) {
    ul.socialMedias {
        position: absolute;
        top: 1.3rem;
        left: 1vw;
    }
}

.centerText .socialMedias {
    margin-top: 2rem;
    display: inline-block;
    position: unset;
    opacity: 1;
}
