@import url('https://fonts.googleapis.com/css2?family=Lexend+Mega&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

body {
    margin: 1vw;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0E0E0E;
    font-size: 0.9rem;
}

body, button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


/*  this is the same breakpoint where portfolio goes 1:2 
    see portfolio.css
*/

@media only screen and (max-width: 640px) {
    body { margin: 1rem; }
}
