.art {
    cursor: move;
    top: -150px;
    top: -5%;
    left: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -100;
}

.artBottomSafeZone {
    display: none;
}

.art::after {
    position: absolute;
    box-shadow: inset 0px 0px 50px rgba(255,0,0,1);

    width: 100%;
    height: 100%;

    z-index: 1000;

    top: 0; left: 0;
}

.content {
    z-index: 100;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 350px;
}

.centerText {
    text-align: center;
    color: white;
    opacity: 0.8;
    font-family: 'Noto Sans', sans-serif;
    margin: 5rem 0;
    font-family: 'Lexend Mega', sans-serif;
    letter-spacing: -2px;
    font-size: 1em;
}

@media only screen and (max-width: 640px) {
    .art {
        top: 0;
        height: 100%;
    }

    .artBottomSafeZone {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40vh;
        width: 100%;
        z-index: 500;
        pointer-events: all;
    }

    .content {
        /* it looks cool when the portfolio is peeking a little bit in the first 100vh on the mobile */
        margin-top: 55vh;
    }
}

